import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"

const FeedFarmSupplies = () => {
  const data = useStaticQuery(graphql`
    query {
      alfalfaBales: file(relativePath: { eq: "farm-business/feed-farm-supplies/alfalfa-bales.png" }) {
        childImageSharp {
          fixed(height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      chickenCoop: file(relativePath: { eq: "farm-business/feed-farm-supplies/chicken-coop.png" }) {
        childImageSharp {
          fixed(height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      chickensWithChickenFeed: file(relativePath: { eq: "farm-business/feed-farm-supplies/chickens-with-chicken-feed.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      customScratch: file(relativePath: { eq: "farm-business/feed-farm-supplies/custom-scratch.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      naturalChickenFeed: file(relativePath: { eq: "farm-business/feed-farm-supplies/natural-chicken-feed.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      naturalHorseFeed: file(relativePath: { eq: "farm-business/feed-farm-supplies/natural-horse-feed.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      pettingHorses: file(relativePath: { eq: "farm-business/feed-farm-supplies/petting-horses.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      superPetRabbitHutch: file(relativePath: { eq: "farm-business/feed-farm-supplies/super-pet-rabbit-hutch.png" }) {
        childImageSharp {
          fixed(height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      tarter: file(relativePath: { eq: "farm-business/feed-farm-supplies/tarter.png" }) {
        childImageSharp {
          fixed(height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      immuneBoost: file(relativePath: { eq: "farm-business/feed-farm-supplies/immune-boost.jpg" }) {
        childImageSharp {
          fixed(height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      mealworms: file(relativePath: { eq: "farm-business/feed-farm-supplies/mealworms.png" }) {
        childImageSharp {
          fixed(height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      },
    }
  `)
  return (
    <Layout>
      <SEO title="Feed / Farm Supplies" />
      <br />
      <h1>Feed / Farm Supplies</h1>
      <div>
        <p><strong>Save up front on your feed:</strong></p>
        <ul>
          <li>Mixed 5 - 50 lb bags - <strong>get 5% off</strong></li>
          <li>Mixed 10 - 50 lb bags - <strong>get 10% off</strong></li>
        </ul>
        <div class="row mb-3">
          <div class="col text-center"><Img alt="Chicken Coop" fixed={data.chickenCoop.childImageSharp.fixed} /></div>
          <div class="col text-center"><Img alt="Tarter" fixed={data.tarter.childImageSharp.fixed} /></div>
          <div class="col text-center"><Img alt="Alfalfa Bales" fixed={data.alfalfaBales.childImageSharp.fixed} /></div>
          <div class="col text-center"><Img alt="Super Pet Rabbit Hutch" fixed={data.superPetRabbitHutch.childImageSharp.fixed} /></div>
        </div>
        <p><strong>Healthy and Sensible</strong> - we focus on the best health for the animal. <strong>Natural, Local and Family Owned.</strong></p>
        
        <h2 id="natural-horse-feed-50lb" style={{ position: `relative` }}><a href="#natural-horse-feed-50lb" aria-label="natural horse feed 50lb permalink" class="anchor before"><svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>Natural Horse Feed 50lb</h2>
        <p><strong>Baxter Barn Quality Feed is a complete feed that is designed to fulfill the diverse nutritional requirements of many different kinds of horses. The Feed has a pro-biotic and a pre-biotic with a complete nutritional diet of organic vitamins and organic/inorganic trace minerals.</strong></p>
        <div class="text-center mb-3"><Img alt="Natural Horse Feed" fixed={data.naturalHorseFeed.childImageSharp.fixed} /></div>
        <p>Current feed on the market has the minimum requirements of nutrition and typically use fillers and less vitamins. Some Horses show deficiencies and need more than the minimum amount to improve their health. Plus we’re one of a few that use a fixed formulation so the amount of each ingredient doesn't change which is best for the animal.</p>
        <p>Market feed price-points are affected mainly by protein and vitamin costs and that is why there are deficiencies in feed.</p>
        <ul>
          <li>Full supplement 12% protein (1 pound per 1,000)</li>
          <li>Low intake, low calories, low sugar and low starch</li>
          <li>NO Corn and NO Soy</li>
          <li>NO GRAIN except wheat bran for digestion</li>
          <li>Whole ground flaxseed to provide both the fat and the omegas</li>
          <li>Suggestions for customer to add….for weight gain, senior and yearlings</li>
        </ul>
        <p><strong>Baxter Barn Quality feed goes beyond the average bag of feed including but not limited to:</strong></p>
        <ul>
          <li>The feeds are fortified for optimum vitamin nutrition</li>
          <li>Contains enzymes to help better digest the feed</li>
          <li>Contains a MOS product to help improve gut function and improve the immune response.</li>
          <li>Contains a Toxin Binder to combat the effects of Myco-toxins that can be present in feed ingredients</li>
          <li>Contains a source of Chelated Trace Minerals to help improve absorption of those nutrients</li>
          <li>Contains a source of Selenium in the form of “Selenium Yeast” which is more readily utilized by the animal</li>
          <li>Extra Zinc and Biotin for strong healthy hoofs</li>
        </ul>
        <h2 id="baxter-barn-natural-energy-pellet-50-lb" style={{ position: `relative` }}><a href="#baxter-barn-natural-energy-pellet-50-lb" aria-label="baxter barn natural energy pellet 50 lb permalink" class="anchor before"><svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>Baxter Barn Natural Energy Pellet 50 lb</h2>
        <p>Feed as a forage supplement for all ages of horses and other livestock. This feed is intended for horses or livestock that would benefit from a lower carb and lower sugars/higher fat diet. It is useful for animals that need to gain weight, maintain weight or working and need energy</p>
        <p>Ingredients: Ground Beet Pulp, Hay, Rice Bran, Ground Flax, Oat Hulls, Vegetable Fat</p>
        <h2 id="baxter-barn-quality-custom-scratch" style={{ position: `relative` }}><a href="#baxter-barn-quality-custom-scratch" aria-label="baxter barn quality custom scratch permalink" class="anchor before"><svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>Baxter Barn Quality Custom Scratch</h2>
        <p><strong>Available in 10 lbs, 20 lbs &amp; 45 lbs. Hen Treat</strong> <a href="https://baxterbarn.org/online-products.html" target="_blank" rel="noopener noreferrer"><strong>available online</strong></a><strong>.</strong></p>
        <div class="text-center mb-3"><Img alt="Custom Scratch" fixed={data.customScratch.childImageSharp.fixed} /></div>
        <p><strong>In addition to the well balanced diet from Baxter Barn Quality Feed,</strong> it is recommended to use treats, scratch, or other food sources in moderation. If you choose to add scratch, Custom Scratch is a finer diverse scratch consisting of oyster shells for calcium and peas for protein, along with 7 other variations of grains that gives a diverse diet added to the Baxter Barn Quality Feed.</p>
        <p>A better alternative to Custom Scratch is our Hen Treat with add meal worms to Custom Scratch and use it as a treat. It is also recommended to add grit to help work with the chicken's muscles to break the feed down to have maximum absorption. To maintain a well-balanced diet it's recommended to not exceed over 6% on any single supplement with the feed!</p>
        <p><strong>PBG Grain Mixes:</strong></p>
        <ul>
          <li>Scratch with Safflower Sunflower with Peas and Rolled Grains</li>
          <li><a href="http://baxterbarn.org/online-products.html" target="_blank" rel="noopener noreferrer">Rabbit 1 lb Treat</a> with Fruits, Vegetables and Grains</li>
          <li>Rabbit 10lb &amp; 20lb with Timothy, Fruit, Vegetables and Grains</li>
          <li>NEW <a href="http://baxterbarn.org/online-products.html" target="_blank" rel="noopener noreferrer">Hen Treat</a> 10 lb: Red wheat, rolled corn, milo, rolled oats, green peas, oats groats, Meal Worms, Safflower, Sunflower and oyster shell.</li>
        </ul>
        <h2 id="17-natural-chicken-feed" style={{ position: `relative` }}><a href="#17-natural-chicken-feed" aria-label="17 natural chicken feed permalink" class="anchor before"><svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>17% Natural Chicken Feed</h2>
        <p><strong>NEW!!! Non GMO Mash (Crumble or Short cut Pellet) 50 lbs</strong></p>
        <p><strong>Baxter Barn Quality Feed is a complete feed that is designed to fulfill the diverse requirements of many different kinds of small urban or large rural poultry. The Feed has a pro-biotic and pre-biotic with a complete nutritional diet of organic vitamins and organic/inorganic trace minerals.</strong></p>
        <div class="text-center mb-3"><Img alt="Natural Chicken Feed" fixed={data.naturalChickenFeed.childImageSharp.fixed} /></div>
        <p>Our NEW layer Mash is: NON GMO, Corn/Soy Free, Gluten Free, All Ingredients are local Sourced and Zero Fish Meal!!!</p>
        <p>Current feed on the market has the minimum requirements of nutrition. Organic and other classifications are at or below that because the availability of resources also. To achieve the full requirement for the heath of the bird we need to use all resources. Some breeds of poultry show deficiencies and need more than the minimum amount to improve their health. Plus we’re one of a few that use a fixed formulation so the amount of each ingredient doesn't change which is best for the animal.</p>
        <p>Market feed price-points are affected mainly by protein and vitamin costs and that is why there are deficiencies in feed. The average feed is 16% protein. From age 6 months to one year, they need 17% to grow and lay at the ideal rate. That simple 1% difference helps your chickens fight stress during changes in temperature, humidity, feed, or adding or losing a bird. That 1% also helps maintain a healthier bird and continues a return on eggs. After the third year egg production drops but to continue egg production and the health of the chicken, 17% is important.</p>
        <p><strong>Baxter Barn Quality feed goes beyond the average bag of feed including but not limited to:</strong></p>
        <ul>
          <li>Essential oils such as oregano to help the immune system and keep the feed fresh</li>
          <li>The feeds are fortified for optimum vitamin nutrition</li>
          <li>Contains enzymes to help better digest the feed</li>
          <li>Contains a MOS product to help improve gut function and improved the immune response.</li>
          <li>Contains a Toxin Binder to combat the effects of Myco-toxins that can be present in feed ingredients</li>
          <li>Contains a source of Chelated Trace Minerals to help improve absorption of those nutrients</li>
          <li>Contains a source of Selenium in the form of "Selenium Yeast" which is more readily utilized by the animal</li>
        </ul>
        <div class="text-center mb-3" style={{maxWidth: `350px`, margin: `auto`}}>
          <Img alt="Chickens with Chicken Feed" fluid={data.chickensWithChickenFeed.childImageSharp.fluid} />
        </div>
      </div>
    </Layout>
  )
}

export default FeedFarmSupplies
